import { DrugField } from '@api/enums';
import createCountryFieldUtil from './countryFieldUtil';

/**
 * Controls the order of options in the dropdown.
 */
export const FIELD_CHOICES = [
    DrugField.label,
    DrugField.indicationsAndUsage,
    DrugField.mechanismOfAction,
    DrugField.descriptionSection,
    DrugField.representedOrganization,
    DrugField.activeIngredient,
    DrugField.inactiveIngredient,
    DrugField.brandName,
    DrugField.dosageAndAdministration,
    DrugField.boxedWarning,
    DrugField.warningsAndPrecautions,
    DrugField.adverseReactions,
    DrugField.contraindications,
    DrugField.drugInteractions,
    DrugField.useInSpecificPopulations,
    DrugField.clinicalStudiesSection,
    DrugField.clinicalPharmacology,
    DrugField.patientCounselingInformation
];

/**
 * Can optionally override the text. Defaults to titleCase.
 */
const NAME_OVERRIDES = {
    [DrugField.label]: 'Search in all sections',
    [DrugField.dosageAndAdministration]: 'Dosage / Administration',
    [DrugField.warningsAndPrecautions]: 'Warnings / Precautions',
    [DrugField.representedOrganization]: 'Company / Packager',
    [DrugField.descriptionSection]: 'Description',
    [DrugField.clinicalStudiesSection]: 'Clinical Studies'
};

export const spl = createCountryFieldUtil<DrugField>(
    DrugField,
    NAME_OVERRIDES
);

// TODO: refactor out usages, and go through the global instead.
export const {
    formatField,
    formatFieldForSubtitle
} = spl;
