import { sentryWarn } from '@features/errors';
import { createIsValidEnum } from '@helpers/filterEnums';
import { titleCase } from '@helpers/textUtils';
import type { CamelCase } from 'type-fest';

export default function createCountryFieldUtil<Field extends string>(
    fieldEnum: Record<string, Field>,
    titleOverrides: Partial<Record<Field, string>> = {}
) {
    /**
     * Store mappings, both from SNAKE_CASE and camelCase to title.
     */
    const knownKeyMapping: Record<string, string> = titleOverrides;
    /**
     * Map from the snake case enum to the title.
     * Will use any provided overrides, or default to title-case.
     */
    const formatField = (field: Field | string): string => {
        const known = knownKeyMapping[field];
        if (known) return known;
        const title = titleCase(field);
        knownKeyMapping[field] = title;
        return title;
    }
    /**
     * Use a different text for the "all" case.
     */
    const formatFieldForSubtitle = (field: Field | string): string => {
        if (field === 'LABEL' || !field) {
            return 'All Sections';
        }
        return formatField(field);
    }
    /**
     * Map from the camel-cased property in the highlights table.
     */
    const getSectionTitle = (key: CamelCase<Field> | string): string => {
        if (knownKeyMapping[key]) return knownKeyMapping[key];
        const upper = fieldEnum[key];
        if (!upper) {
            sentryWarn(`Encountered unknown field ${key}`);
        }
        const label = formatField(upper);
        knownKeyMapping[key] = label;
        // console.log(`added ${key} - ${upper} - ${label}`);
        return label;
    }
    /**
     * Check if a string is applicable to this country.
     */
    const isValidField = createIsValidEnum(fieldEnum);
    /**
     * Map from the camel-cased property in the highlights table.
     */

    return {
        isValidField,
        fieldEnum,
        formatField,
        formatFieldForSubtitle,
        getSectionTitle
    }
}
