import { DrugField } from '@api/enums';
import type { PharmaDatasetIdentifier, PharmaDatasetInDbUnion } from '@features/saved-items';
import { createEntityAdapter, createSlice, EntityState, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { partition } from 'lodash';
import type { DrugSearchResponse } from '../../API';
import { pickMultiInitial } from '../../Demo/data/pickBestMatch';
import type { MultiFormState, SearchFormState } from '../../Demo/Search/multiUtil';
import { filtersToMulti, initialFormState } from '../../Demo/Search/multiUtil';
import type { LabelSearchColumn } from './types';

export const columnEntityAdapter = createEntityAdapter<LabelSearchColumn>();

export type TableType = 'guidance' | 'label' | 'labelTrials' | 'searchTrials';

interface DatasetState extends PharmaDatasetIdentifier {
    loaded?: boolean;
}

// Note: adding properties here makes it easier to clear them when resetting the column state.
interface ColumnsState extends EntityState<LabelSearchColumn> {
    // Listen to count for this column and show a snackbar when the count is available.
    awaitingCount: string | null;
}

export type LabelSearchCountry = 'us' | 'eu' | 'combined';

export interface LabelSearchState {
    // ??? Should I store the MultiFormState??  or as columns??
    // Should guidance search be stored on a separate property??
    drugSearch: SearchFormState & {
        needsInitialCheckboxes: boolean;
        country: LabelSearchCountry;
    };
    // Right now, this is only the added columns.
    columns: ColumnsState;
    // Note: can't just look at last item in array because don't want to scroll when removing.
    scrollToColumn: string | null;
    // Don't want to keep columns from a different table type.
    columnsApplyTo: TableType;
    // If viewing a previously saved search.
    dataset?: DatasetState | null;
}

const initialState: LabelSearchState = {
    drugSearch: {
        ...initialFormState,
        needsInitialCheckboxes: true,
        country: 'us'
    },
    columns: columnEntityAdapter.getInitialState({
        awaitingCount: null
    }),
    scrollToColumn: null,
    columnsApplyTo: 'label'
};

const fillOptionalFormProperties = (formState: Partial<SearchFormState>): LabelSearchState['drugSearch'] => {
    const { query = '', actives = [], brands = [], multi } = formState;
    return {
        query,
        actives,
        brands,
        // TODO: validate that multi has all properties?
        multi: multi ?? initialState.drugSearch.multi,
        needsInitialCheckboxes: brands.length === 0,
        country: 'us' // TODO: support other countries.
    };
}

interface DatasetLoadedPayload {
    columns: LabelSearchColumn[];
    search: Partial<SearchFormState>;
    dataset: PharmaDatasetInDbUnion;
}

/**
 * Extract the column objects and the search info from a saved dataset.
 * Note: setting of a random id should happen outside of the reducer.
 */
const prepareDatasetPayload = (dataset: PharmaDatasetInDbUnion): DatasetLoadedPayload => {
    if (dataset.datasetType === 'MULTI_DRUG') {
        const [added, fixed] = partition(dataset.filters ?? [], filter => filter.labelOnly);
        const multi = filtersToMulti(fixed);
        const columns: LabelSearchColumn[] = added.map(filter => ({
            id: nanoid(4),
            field: filter.field as DrugField,
            query: filter.values?.[0] ?? '' // TODO: always exactly 1 entry?
        }));
        return {
            columns,
            search: { multi },
            dataset
        }
    } else if (dataset.datasetType === 'GUIDANCE' || dataset.datasetType === 'DRUG_TRIAL') {
        // TODO: need to support the within on DRUG_TRIAL
        const first = dataset.datasetQueries[0];
        const fixed = dataset.datasetQueries.slice(1);
        const columns: LabelSearchColumn[] = fixed.map(o => ({
            id: nanoid(4),
            field: DrugField.label, // TODO: optional?
            query: o.query
        }));
        return {
            columns,
            search: { query: first.query },
            dataset
        }
    } else {
        throw new Error(`dataset type ${dataset.datasetType} not yet supported`);
    }
}

const labelSearchSlice = createSlice({
    name: 'pharma/label',
    initialState,
    reducers: {

        /**
         * ------------------------- SETUP -------------------------
         */
        /**
         * Create the initial state from a Multi-search with multiple fields.
         * Can use a partial state from history location.
         * Will clear all columns.
         */
        openSearch: (state, action: PayloadAction<Partial<SearchFormState>>) => {
            state.drugSearch = fillOptionalFormProperties(action.payload);
            state.columns = initialState.columns;
            state.scrollToColumn = null;
            state.dataset = null;
            // TODO: ensure that all fields will have a unique key for the columns.
        },
        /**
         * Remove all current search and start over.
         */
        startNewSearch: () => {
            return initialState;
        },
        /**
         * Clears all columns but keeps the search.
         */
        resetColumns: (state) => {
            state.columns = initialState.columns;
            state.scrollToColumn = null;
        },
        /**
         * Action to dispatch when going from a search form to a table.
         * Clears the columns, if necessary.
         * Keeps the columns if editing a search and staying on the same table.
         */
        openTableType: (state, action: PayloadAction<{ tableType: TableType; isEdit: boolean }>) => {
            const { tableType, isEdit } = action.payload;
            const shouldKeep = isEdit && tableType === state.columnsApplyTo;
            if (!shouldKeep) {
                state.columns = initialState.columns;
                state.scrollToColumn = null;
            }
        },
        /**
         * Set the datasetId and wait for the rest of the details.
         */
        openDataset: (state, action: PayloadAction<PharmaDatasetIdentifier>) => {
            const { _id, datasetType } = action.payload;
            // Makes sure that `loaded` doesn't get overwritten if the action is called again.
            if (_id !== state.dataset?._id) {
                state.dataset = {
                    _id,
                    datasetType,
                    loaded: false
                };
            }
        },
        /**
         * Use a separate action before the `openDataset` to indicate that
         * any brand checkboxes should not be cleared.
         */
        datasetSaved: (state, action: PayloadAction<PharmaDatasetInDbUnion>) => {
            // TODO setting loaded:true without applying the columns is a bit weird.
            const { _id, datasetType } = action.payload;
            state.dataset = {
                _id,
                datasetType,
                loaded: true
            };
        },
        /**
         * Set the columns and search based on the loaded dataset.
         */
        datasetLoaded: {
            reducer: (state, action: PayloadAction<DatasetLoadedPayload>) => {
                const { search, columns, dataset } = action.payload;
                // Do not clear the brand selections if this dataset has been loaded before.
                // TODO: but should clear them if the search is different than before, once editing is implemented.
                const { brands, actives, needsInitialCheckboxes } = state.drugSearch;
                state.drugSearch = fillOptionalFormProperties(search);
                if (dataset._id === state.dataset?._id && state.dataset?.loaded) {
                    Object.assign(state.drugSearch, { brands, actives, needsInitialCheckboxes });
                }
                columnEntityAdapter.setAll(state.columns, columns);
                // Could check if it matches.
                state.dataset = {
                    _id: dataset._id,
                    datasetType: dataset.datasetType,
                    loaded: true
                };
                //state.columnsApplyTo ??
            },
            prepare: (dataset: PharmaDatasetInDbUnion) => {
                return {
                    payload: prepareDatasetPayload(dataset)
                }
            }
        },
        /**
         * Set the brand selections from the drug search response, and stop listening for it.
         */
        setInitialCheckboxes: (state, action: PayloadAction<DrugSearchResponse>) => {
            const { actives, brands } = pickMultiInitial(action.payload);
            state.drugSearch.actives = actives;
            state.drugSearch.brands = brands;
            state.drugSearch.needsInitialCheckboxes = false;
        },
        /**
         * ------------------------- EDITING TABLE -------------------------
         */
        /**
         * When the user adds a new search it will initially search in all fields.
         * Requires only the query.
         */
        addSearchWithinColumn: {
            reducer: (state, action: PayloadAction<LabelSearchColumn>) => {
                columnEntityAdapter.addOne(state.columns, action.payload);
                state.scrollToColumn = action.payload.id;
                state.columns.awaitingCount = action.payload.id;
            },
            prepare: (query: string) => {
                const id = nanoid(4);
                return {
                    payload: {
                        id,
                        field: DrugField.label,
                        query
                    }
                };
            }
        },
        /**
         * Add a column to display the entire text of a section of the label.
         * Requires only the section.
         */
        addLabelTextColumn: {
            reducer: (state, action: PayloadAction<LabelSearchColumn>) => {
                columnEntityAdapter.addOne(state.columns, action.payload);
                state.scrollToColumn = action.payload.id;
                state.columns.awaitingCount = action.payload.id;
            },
            prepare: (section: DrugField) => {
                const id = nanoid(4);
                return {
                    payload: {
                        id,
                        field: section,
                        query: ''
                    }
                };
            }
        },
        /**
         * Can change the section of a search within column.
         * Requires the column id and the new section field.
         */
        changeColumnSection: (state, action: PayloadAction<{ id: string; field: DrugField }>) => {
            const { id, field } = action.payload;
            const existing = state.columns.entities[id];
            existing.field = field;
        },
        /**
         * Add a search within text to a full-text column, or alter the existing text.
         * Requires the column id and the query.
         */
        changeColumnQuery: (state, action: PayloadAction<{ id: string; query: string }>) => {
            const { id, query } = action.payload;
            const existing = state.columns.entities[id];
            existing.query = query;
        },
        /**
         * Any column which was added by the user can be removed.
         * Requires only the id of the column.
         */
        removeColumn: (state, action: PayloadAction<string>) => {
            columnEntityAdapter.removeOne(state.columns, action.payload);
        },
        /**
         * Respond to changes in brand checkboxes, from the table or the list.
         */
        changeBrandCheckboxes: (state, action: PayloadAction<string[]>) => {
            state.drugSearch.brands = action.payload;
        },
        /**
         * ------------------------- STOP LISTENERS -------------------------
         */
        /**
         * Remove the scroll column id from the state after scrolling.
         * Prevents the effect from re-running if switching between table and search.
         */
        didColumnScroll: (state) => {
            state.scrollToColumn = null;
        },
        /**
         * Stop listening for column count once the snackbar has been shown.
         */
        didShowSnackbar: (state) => {
            state.columns.awaitingCount = null;
        },
        /**
         * ------------------------- EDITING SEARCH FORM -------------------------
         */
        /**
         * Replaces the search but preserves the columns.
         */
        editSearch: (state, action: PayloadAction<SearchFormState | MultiFormState>) => {
            state.drugSearch = {
                query: '',
                ...action.payload,
                needsInitialCheckboxes: !(action.payload.brands?.length > 0),
                country: 'us'
            };
        },
        changeCountry: (state, action: PayloadAction<LabelSearchCountry>) => {
            // Right now this clears the form.
            state.drugSearch = {
                ...initialState.drugSearch,
                country: action.payload
            };
        },
        clearMultiSearch: (state) => {
            state.drugSearch = {
                ...initialState.drugSearch,
                country: state.drugSearch.country
            }
        },
        addMultiSearchInput: (state, action: PayloadAction<string>) => {
            state.drugSearch.multi.inputs.push({
                field: action.payload,
                text: ''
            });
        },
        removeMultiSearchInput: (state, action: PayloadAction<number>) => {
            const index = action.payload;
            state.drugSearch.multi.inputs.splice(index, 1);
        },
        editMultiSearchInput: (state, action: PayloadAction<{ index: number; text: string; }>) => {
            const { index, text } = action.payload;
            // Drop any unii when editing the text
            const existing = state.drugSearch.multi.inputs[index];
            delete existing.unii;
            existing.text = text;
        },
        editMultiSearchCheckboxes: (state, action: PayloadAction<{ kind: 'routes' | 'categories'; values: string[] }>) => {
            const { kind, values } = action.payload;
            state.drugSearch.multi[kind] = values;
        }
    }
});

export const {
    openSearch,
    startNewSearch,
    resetColumns,
    openTableType,
    openDataset,
    datasetSaved,
    datasetLoaded,
    setInitialCheckboxes,
    addSearchWithinColumn,
    addLabelTextColumn,
    changeColumnSection,
    changeColumnQuery,
    removeColumn,
    didColumnScroll,
    didShowSnackbar,
    changeBrandCheckboxes,
    editSearch,
    changeCountry,
    clearMultiSearch,
    addMultiSearchInput,
    removeMultiSearchInput,
    editMultiSearchInput,
    editMultiSearchCheckboxes
} = labelSearchSlice.actions;

export default labelSearchSlice.reducer;
