import { DrugField, FaersField } from '@api/enums';
import { formatKey } from '@content/Facet/formatLabels';
import { MultiDrugDatasetFilter } from '@features/saved-items';
import { createIsValidEnum } from '@helpers/filterEnums';
import { safeAdd } from '@helpers/numberUtils';
import { SkipToken, skipToken } from '@reduxjs/toolkit/query';
import type { Count, DrugDatasetQuery, DrugFilterCountResponse, DrugQuery, IngredientQuery } from '../../API';
import type { IngredientSuggestion } from '../data/pharmaDemoReducer';
import type { ContentsItem } from '../Header/MultiContents';
import { formatField } from './Fields/fieldsSPL';

export interface FormMultiCriterion {
    /**
     * The filter field.
     */
    field: string;
    /**
     * The content of the input.
     */
    text: string;
    /**
     * Can override the values sent to the API.
     * Used when creating a multi-search based on ingredients by unii.
     * Cannot be created from the form itself, which only uses name.
     */
    unii?: string;
}

export interface MultiSearchState {
    inputs: FormMultiCriterion[];
    routes: string[];
    categories: string[];
}

// Note: matches location state
export interface MultiFormState {
    multi: MultiSearchState;
    brands: string[];
    actives: string[];
}

export interface SearchFormState extends MultiFormState {
    query: string;
}

export const initialFormState: SearchFormState = {
    query: '',
    actives: [],
    brands: [],
    multi: {
        inputs: [],
        routes: [],
        categories: []
    }
}

export type MultiQueryArg = SkipToken | DrugDatasetQuery;

const isDrugField = createIsValidEnum(DrugField);

// Check for empty fields and empty values.
export const isValidCriterion = (criteria: FormMultiCriterion): boolean =>
    criteria.field.length > 0 && criteria.text.trim().length > 0;

const hasFilters = (state: FormMultiCriterion[]): boolean =>
    state.some(isValidCriterion);

/**
 * Need to require the values array for compatibility with MultiDrugDatasetFilter interface.
 */
export type CreatedFilter<F extends string = string> = {
    field: F;
    values: string[];
    operator?: 'OR'
}

/**
 * Convert form fields into API filters, preserving order.
 * Handle fields which need to be sent to the API using code instead of text.
 * Check that field is applicable and text is not empty.
 * Note: don't combine multiple inputs for the same field in order to assign filter
 */
export const fieldsToFilters = (inputs: FormMultiCriterion[]): CreatedFilter<DrugField>[] => {
    const valid: CreatedFilter<DrugField>[] = [];
    inputs.forEach(({ field, text, unii }) => {
        if (isDrugField(field)) {
            if (unii && field === DrugField.activeIngredient) {
                return {
                    field: DrugField.activeCodeIngredient,
                    values: [unii]
                }
            } else if (unii && field === DrugField.inactiveIngredient) {
                return {
                    field: DrugField.inactiveCodeIngredient,
                    values: [unii]
                }
            } else {
                const trimmed = text.trim();
                if (trimmed.length > 0) {
                    valid.push({
                        field,
                        values: [trimmed]
                    });
                }
            }
        }
    });
    return valid;
}

/**
 * Adds the filters for categories and administration routes,
 * in addition to the filters from form fields.
 */
export const stateToFilters = (state: Partial<MultiSearchState>): CreatedFilter<DrugField>[] => {
    const filters = fieldsToFilters(state.inputs ?? []);
    // Do not add additional filters if there are no core filters -- want to skip.
    if (state.routes?.length > 0 && filters.length > 0) {
        filters.push({
            field: DrugField.administrationRoute,
            values: state.routes,
            operator: 'OR'
        });
    }
    if (state.categories?.length > 0 && filters.length > 0) {
        filters.push({
            field: DrugField.drugCategory,
            values: state.categories,
            operator: 'OR'
        });
    }
    return filters;
}

/**
 * Create an API query from the form state.
 */
export const nonEmptyStateToQuery = (
    state: Partial<MultiSearchState>
): DrugQuery => {
    return ({
        filters: stateToFilters(state),
        faersFilters: [],
        orderBy: {
            fieldName: 'FAERS',
            ascending: false
        }
    });
}

/**
 * Create an API query from the form state,
 * returning a skip token if the query is empty.
 */
export const stateToQuery = (
    state: Partial<MultiSearchState> | undefined
): MultiQueryArg => {
    if (!state || !state.inputs?.length) return skipToken;
    const query = nonEmptyStateToQuery(state);
    if (query.filters.length === 0) return skipToken;
    return query;
}

export const sumCounts = (a: Count, b: Count): Count => ({
    clinicalTrial: safeAdd(a?.clinicalTrial, b?.clinicalTrial),
    faers: safeAdd(a?.faers, b?.faers)
});

/**
 * Convert an ingredient from autosuggest into an initial form state.
 */
export const ingredientToState = ({ name, isActive }: IngredientSuggestion): MultiSearchState => {
    return {
        inputs: [{
            field: isActive ? DrugField.activeIngredient : DrugField.inactiveIngredient,
            text: name
        }],
        routes: [],
        categories: []
    }
};

export const _ingredientToState = (ingredient: string, isActive: boolean): MultiSearchState =>
    ingredientToState({ name: ingredient, isActive });

export const ingredientToQuery = (ingredient: IngredientSuggestion): DrugQuery =>
    nonEmptyStateToQuery(ingredientToState(ingredient));

/**
 * Work backwards from an array of filters
 * from a dataset.
 */
export const filtersToMulti = (filters: MultiDrugDatasetFilter[]): MultiSearchState => {
    const inputs: FormMultiCriterion[] = [];
    const routes: string[] = [];
    const categories: string[] = [];
    filters.forEach((filter) => {
        const { field, values } = filter;
       if (field === FaersField.administrationRoute) {
           routes.push(...values);
       } else if (field === DrugField.drugCategory) {
           categories.push(...values);
       } else if (isDrugField(field)) {
           inputs.push(...(values).map(value => ({
               field,
               text: value
           })));
       }
    });
    return {
        inputs,
        routes,
        categories
    }
}

/**
 * Convert a multi-search state into data for the "i" contents popup.
 */
export const multiToContents = ({ inputs, routes, categories }: MultiSearchState): ContentsItem[] => {
    // Note: could combine multiples of the same field.
    const items = inputs.map((input) => ({
        title: formatField(input.field),
        values: [input.text.trim()]
    })).filter(o => o.values[0].length > 0);
    if (routes.length > 0) {
        items.push({
            title: 'Administration Route',
            values: routes.map(formatKey('administrationRoute'))
        });
    }
    if (categories.length > 0) {
        items.push({
            title: 'Drug Category',
            values: categories.map(formatKey('drugCategory'))
        });
    }
    return items;
}

const uniiField = (ingredient: IngredientQuery): FormMultiCriterion => {
    return {
        // Use the name field rather than the code field so that it works with the edit UI
        field: ingredient.isActive ? DrugField.activeIngredient : DrugField.inactiveIngredient,
        text: ingredient.name?.toLowerCase(),
        unii: ingredient.fdaUniiCode
    }
}

export const uniiQueryToState = (ingredients: IngredientQuery[]): MultiSearchState => {
    // Note: assumes the actives are already first.
    return {
        inputs: ingredients.map(uniiField),
        routes: [],
        categories: []
    }
}

export interface StepCount {
    drugs: number;
    ingredients: number;
}

export interface FieldWithCounts extends FormMultiCriterion {
    counts?: StepCount;
}

/**
 * Empty values are dropped before sending to API, so need to skip some.
 */
export const assignCountsToFields = (
    state: FormMultiCriterion[],
    counts: DrugFilterCountResponse['filterCounts'] | undefined
): FieldWithCounts[] => {
    if (!counts) {
        return state;
    }
    const apiCounts = Object.values(counts) as StepCount[];
    let iCounts = 0;
    const mappedFields: FieldWithCounts[] = [];
    state.forEach((criteria, i) => {
       if (isValidCriterion(criteria)) {
           const counts = apiCounts[iCounts];
           mappedFields[i] = { ...criteria, counts };
           iCounts++;
       } else {
           mappedFields[i] = criteria;
       }
    });
    return mappedFields;
}
